<template>
  <div> 
    <div class="p-4 border-b">
      <div class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <a class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
          <!--ТУТ ДОЛЖНО БЫТЬ ЛОГО (ml-3)-->
          <a class="text-xl text-black">
            <router-link to="/">CryptoDragons</router-link>
          </a>
        </a>
        <nav class="md:ml-auto flex flex-wrap items-center text-base text-black justify-center">
          <a class="mr-5 hover:text-pink-500 cursor-pointer">
            <router-link to="/">Home</router-link>
          </a>
          <a class="mr-5 hover:text-pink-500 cursor-pointer">
            <router-link to="/dragons">Dragons</router-link>
          </a>
          
          <a class="mr-5 hover:text-pink-500 cursor-pointer">
            <router-link to="/statistics">Statistics</router-link>
          </a>
          <a class="mr-5 hover:text-pink-500 cursor-pointer">
            <router-link to="/about">About</router-link>
          </a>
        </nav>
        <label v-if=0 for="modal-login" class="inline-flex items-center bg-pink-500 border-0 py-1 px-3 focus:outline-none hover:bg-pink-600 rounded text-base mt-4 md:mt-0 cursor-pointer">
          <span class="text-white font-semibold">Sing in</span>
        </label>
        <router-link v-else to="/myAccount">
          <button class="inline-flex items-center bg-pink-500 border-0 py-1 px-3 focus:outline-none hover:bg-pink-600 rounded text-base mt-4 md:mt-0 text-white font-semibold">My account</button>
        </router-link>
      </div>
    </div>

    <!--modal-->
    <input type="checkbox" id="modal-login" class="modal-toggle"> 
    <div class="modal">
      <div class="modal-box">
        <h1 class="text-gray-900 font-medium title-font font-black">Sing in</h1>
        <p class="mb-4">If you already have Crypto Dragons account, then enter its details here. (Note! It is not your existing TON wallet in Surf and etc).</p> 
        <div class="relative">
            <label for="full-name" class="leading-7 text-sm text-gray-600">Public key</label>
            <input type="text" id="full-name" name="full-name" placeholder="0x4xc0...a5a" class="w-full bg-white rounded border border-gray-300 focus:border-pink-500 focus:ring-2 focus:ring-pink-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
        </div>
        <div class="relative">
          <label for="email" class="leading-7 text-sm text-gray-600">Secret key</label>
          <input type="email" id="email" name="email" placeholder="Your address" class="w-full bg-white rounded border border-gray-300 focus:border-pink-500 focus:ring-2 focus:ring-pink-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
        </div>
        <div class="modal-action">
          <label for="modal-login" class="text-white font-semibold bg-pink-500 border-0 py-2 px-8 focus:outline-none hover:bg-pink-600 rounded text-lg">Log in</label>
          <label for="modal-login" class="text-white font-semibold bg-gray-500 border-0 py-2 px-8 focus:outline-none hover:bg-gray-600 rounded text-lg">Close</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

</script>

<style>

</style>
