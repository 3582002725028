<template>
  <div class="home min-h-screen">
    <Nav />
    
    <div>
      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto flex flex-wrap items-center">
          <div class="lg:w-3/5 md:w-1/2 md:pr-16 lg:pr-0 pr-0">
            <div class="text-pink-500 font-black text-xs"> 
              <span>NFT · </span>
              <span>BLOCKCHAIN · </span>
              <span>TON</span>
            </div>
            <h1 class="title-font font-medium text-3xl text-gray-900 font-bold">What is this?</h1>
            <p class="leading-relaxed text-lg mt-4">Crypto Dragons is a NFT collectable, inspired by CryptoPunks. There are 1024 unique dragons generated by StyleGAN 2. To get started transfer funds and mint your first dragon! Currently minting new dragon costs 10 TON.</p>
          </div>
          <div class="lg:w-2/6 md:w-1/2 bg-gray-100 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 shadow-md">
            <h2 class="text-gray-900 text-lg font-medium title-font mb-5">Create new account</h2>
            <div class="relative mb-4">
              <label for="email" class="leading-7 text-sm text-gray-600">Email</label>
              <input type="email" id="email" name="email" placeholder="Your email address" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out placeholder-opacity-75">
            </div>
            <div class="relative mb-4">
              <label for="full-name" class="leading-7 text-sm text-gray-600">Nickname</label>
              <input type="text" id="full-name" name="full-name" placeholder="Nickname (optional)" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out placeholder-opacity-75">
            </div>
            <button class="text-white bg-pink-500 border-0 py-2 px-8 focus:outline-none hover:bg-pink-600 rounded text-lg font-semibold">Sing up</button>
            <p class="text-xs text-center text-gray-500 mt-3">If you find a problem, write to <u>support</u>.</p>
          </div>
        </div>
      </section>
    </div>

    <div>
      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto">
          <div class="flex flex-wrap -m-4 text-center">
            <div class="p-4 sm:w-1/4 w-1/2">
              <h2 class="title-font font-medium sm:text-4xl text-3xl text-pink-500">2.7K</h2>
              <p class="leading-relaxed">Dragons</p>
            </div>
            <div class="p-4 sm:w-1/4 w-1/2">
              <h2 class="title-font font-medium sm:text-4xl text-3xl text-pink-500">1.8K</h2>
              <p class="leading-relaxed">Sales</p>
            </div>
            <div class="p-4 sm:w-1/4 w-1/2">
              <h2 class="title-font font-medium sm:text-4xl text-3xl text-pink-500">35</h2>
              <p class="leading-relaxed">Mint fee</p>
            </div>
            <div class="p-4 sm:w-1/4 w-1/2">
              <h2 class="title-font font-medium sm:text-4xl text-3xl text-pink-500">4</h2>
              <p class="leading-relaxed">My dragons</p>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div>
      <section class="text-gray-700 body-font bg-gray-100">
        <div class="container px-5 py-24 mx-auto">
          <div class="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
            <h1 class="title-font font-bold text-3xl text-gray-900 pb-4"><span class="text-pink-500">?</span> What is CryptoDragons <span class="text-pink-500">?</span></h1>
            <p class="leading-relaxed text-lg">
              CryptoDragons is a game centered around collectible and impressive creatures we call CryptoDragons! Each dragon is one-of-a-kind and 100% owned by you; it cannot be replicated, taken away, or destroyed.
            </p>
            <!--<p class="leading-relaxed text-lg">
              PubKey - is like your username. All tokens are not bound to your address, but to a public key, so Public key is your main identifier in the service.<br>
              Secret key - is like your password. All transactions are signed with a secret key. So a bundle of public-private keys is similar to the username-password combination in regular services. But here everything is stored in the blockchain, and if you lose the secret key, you will not be able to restore access to your account.<br>
              Wallet. The wallet address is used to top up the balance from any TON wallet (ex: ton.surf or extraton).<br>
              This wallet is used to pay for any actions in DragonsTON. You can withdraw funds from it to any other wallet at any time.<br>
              Minting new Dragons. If not all NFT tokens have been distributed yet, then any user can "mint" a new one for a small fee.<br>
              We have set up a dynamic commission setting for minting tokens. This means that the more tokens are already distributed, the more expensive it will be to mint new ones:<br>
              • 0-256 dragons — 10 💎<br>
              • 257-512 dragons — 20 💎<br>
              • 513-1024 dragons — 40 💎<br>
              • 1025-2048 dragons — 60 💎
            </p>
            -->
          </div>
        </div>
      </section>
    </div>

    <div>
      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto flex flex-wrap">
          <div class="flex flex-wrap w-full">
            <div class="lg:w-2/5 md:w-1/2 md:pr-10 md:py-6">
              <div class="flex relative pb-12">
                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                </div>
                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-pink-500 inline-flex items-center justify-center text-white relative z-10">
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </div>
                <div class="flex-grow pl-4">
                  <h2 class="font-bold title-font text-sm text-gray-900 mb-1 tracking-wider">STEP 1</h2>
                  <p class="leading-relaxed">First, you will need to create a dragon wallet, if you do not already have one. Crypto Dragons only works with its own wallet type. But don't worry! You can always transfer funds between any other TON wallets.</p>
                </div>
              </div>
              <div class="flex relative pb-12">
                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                </div>
                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-pink-500 inline-flex items-center justify-center text-white relative z-10">
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </div>
                <div class="flex-grow pl-4">
                  <h2 class="font-bold title-font text-sm text-gray-900 mb-1 tracking-wider">STEP 2</h2>
                  <p class="leading-relaxed">First, you will need to create a dragon wallet, if you do not already have one. Crypto Dragons only works with its own wallet type. But don't worry! You can always transfer funds between any other TON wallets.</p>
                </div>
              </div>
              <div class="flex relative pb-12">
                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                </div>
                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-pink-500 inline-flex items-center justify-center text-white relative z-10">
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </div>
                <div class="flex-grow pl-4">
                  <h2 class="font-bold  title-font text-sm text-gray-900 mb-1 tracking-wider">STEP 3</h2>
                  <p class="leading-relaxed">First, you will need to create a dragon wallet, if you do not already have one. Crypto Dragons only works with its own wallet type. But don't worry! You can always transfer funds between any other TON wallets.</p>
                </div>
              </div>
              <div class="flex relative pb-12">
                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                </div>
                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-pink-500 inline-flex items-center justify-center text-white relative z-10">
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </div>
                <div class="flex-grow pl-4">
                  <h2 class="font-bold title-font text-sm text-gray-900 mb-1 tracking-wider">STEP 4</h2>
                  <p class="leading-relaxed">First, you will need to create a dragon wallet, if you do not already have one. Crypto Dragons only works with its own wallet type. But don't worry! You can always transfer funds between any other TON wallets.</p>
                </div>
              </div>
              <div class="flex relative">
                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-pink-500 inline-flex items-center justify-center text-white relative z-10">
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                    <path d="M22 4L12 14.01l-3-3"></path>
                  </svg>
                </div>
                <div class="flex-grow pl-4">
                  <h2 class="font-bold title-font text-sm text-gray-900 mb-1 tracking-wider">FINISH</h2>
                  <p class="leading-relaxed">First, you will need to create a dragon wallet, if you do not already have one. Crypto Dragons only works with its own wallet type. But don't worry! You can always transfer funds between any other TON wallets.</p>
                </div>
              </div>
            </div>
            <img class="lg:w-3/5 md:w-1/2 object-cover object-center rounded-lg md:mt-0 mt-12" src="https://images6.alphacoders.com/842/842336.jpg" alt="step">
          </div>
        </div>
      </section>
    </div>

    <div>
      <section class="text-gray-600 body-font">
        <div class="container px-5 pb-24 mx-auto">
          <div class="flex flex-wrap -m-4">
            <div class="p-4 lg:w-1/3">
              <div class="h-full bg-gray-100 bg-opacity-75 px-8 pt-16 pb-16 rounded-lg overflow-hidden text-center relative">
                <div> 
                  <h1 class="title-font sm:text-2xl text-xl font-bold text-gray-900 mb-3">Ultra-Rare NFTs</h1>
                  <p class="leading-relaxed mb-3">Supercharge your collection by unpacking $PMON tokens to reveal ultra-rare Polkamon or swap your NFTs for $PMON tokens.</p>
                </div>
              </div>
            </div>
            <div class="p-4 lg:w-1/3">
              <div class="h-full bg-gray-100 bg-opacity-75 px-8 pt-16 pb-16 rounded-lg overflow-hidden text-center relative">
                <h1 class="title-font sm:text-2xl text-xl font-bold text-gray-900 mb-3">Hyper-Deflationary</h1>
                <p class="leading-relaxed mb-3">Each swap & reverse swap burns $PMON, ensuring a continued increase in scarcity of your NFTs.</p>
              </div>
            </div>
            <div class="p-4 lg:w-1/3">
              <div class="h-full bg-gray-100 bg-opacity-75 px-8 pt-16 pb-16 rounded-lg overflow-hidden text-center relative">
                <h1 class="title-font sm:text-2xl text-xl font-bold text-gray-900 mb-3">Staking</h1>
                <p class="leading-relaxed mb-3">Stake $PMON and Polkamon to receive additional $PMON rewards for every swap between $PMON & Polkamon.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    
    
    <Footer />
  </div>
</template>

<script>
  import Nav from "../components/Nav";
  import Footer from "../components/Footer";

  import { TonClient } from '@tonclient/core';
  import { libWeb } from '@tonclient/lib-web';

  import freeton from 'freeton';

  
  TonClient.useBinaryLibrary(libWeb);
  const client = new TonClient({
    network: { 
        server_address: 'https://net.ton.dev' // 'https://main.ton.dev'
    } 
  });

  if (typeof window.freeton === 'undefined') {
    
  }else{
    var expp = new freeton.providers.ExtensionProvider(window.freeton);

    web3();
  }

  async function web3(){
    //console.log(await expp.getNetwork());
    console.log(await expp.getSigner());
    var signer = await expp.getSigner();
    var wallet = await signer.getWallet();
    console.log(wallet);

    wallet.transfer(wallet.address, "1000000000", true,  null);

  }
  
  export default {
    components: {Nav, Footer},
    async created() {
      console.log( await client.crypto.mnemonic_from_random({
        dictionary: 1,
        word_count: 12
      }))
    }
  }
</script>

<style scoped>

</style>